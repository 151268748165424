import React, { Suspense, lazy, memo, useState } from 'react';
import ReactDOM from 'react-dom';

import Spinner from 'components/Spinner';
import getProofingSessionId from 'services/session/getProofingSessionId';
import getSelfStartUrlParameters from 'services/session/getSelfStartUrlParameters';
import isProofingPreview from 'services/session/isProofingPreview';

const LegacyApp = lazy(() => import('./__legacy/App'));
const App = lazy(() => import('./App'));

/**
 * Temporary component until we delete Legacy IPA Web Demo located in `__legacy` folder.
 * When deleted, this render app should disappear, and we should only render `App` from `src` folder.
 */
const AppRender = memo(() => {
  const [isLegacyApp] = useState(
    // When no `previewData`, `jwt` or `sessionId`  present in URL, resort to Legacy Web Demo app.
    !isProofingPreview() && !getProofingSessionId() && !getSelfStartUrlParameters().token,
  );

  if (isLegacyApp) {
    return (
      <Suspense fallback={<Spinner />}>
        <LegacyApp />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<Spinner />}>
      <App />
    </Suspense>
  );
});

AppRender.displayName = 'AppRender';

ReactDOM.render(
  <React.StrictMode>
    <AppRender />
  </React.StrictMode>,
  document.getElementById('root'),
);
