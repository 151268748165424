/**
 * Searches url for `sessionId` param. This url query param is sent in Entra and IPA flows.
 * {@link https://idemiadigitallabs.atlassian.net/wiki/spaces/DP/pages/3653501119/IPA+Backend+Implementation+Changes+with+Multi+DocType+Country}
 * @returns Proofing Session ID associated with IPA or Entra flow of the app.
 */
function getProofingSessionId(): string | null {
  return new URLSearchParams(window.location.search).get('sessionId');
}

export default getProofingSessionId;
