import clsx from 'clsx';
import { FunctionComponent, useMemo } from 'react';

import styles from './Spinner.module.scss';

interface SpinnerProps {
  isCentered?: boolean;
  isInline?: boolean;
  size?: string;
}

const TAG = 'Spinner';

const Spinner: FunctionComponent<SpinnerProps> = ({ children, isCentered = true, isInline = false, size = '6rem' }) => {
  const spinnerSize = useMemo(() => {
    return { height: size, width: size };
  }, [size]);

  return (
    <div
      data-testid={TAG}
      className={clsx(isInline ? 'absolute' : styles.spinnerContent, isCentered && styles.centerContent)}>
      <div className={styles.spinnerWrapper} style={spinnerSize}>
        <div className={styles.spinnerBack} style={spinnerSize} />
        <div className={styles.spinnerFront} style={spinnerSize} />
      </div>

      {children && <div className="mt-2">{children}</div>}
    </div>
  );
};

Spinner.displayName = TAG;

export default Spinner;
