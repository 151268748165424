import getParsedJwt from 'utils/getParsedJwt';

export interface TokenPayload {
  /**
   * Used to identify organization to be used to start IPA session.
   */
  OrgId: string;
  /**
   * Unique Id of our system. This will help in livesite issue debugging  where we can provide this Id to map/back track in your system.
   */
  RequestId: string;
  /**
   * Url to send user to after process is finished or stopped due to error.
   */
  redirectUrl: string;
  /**
   * Unique id of specific Application Configuration.
   */
  ApplicationId: string;
  /**
   * Locale (language) code. This will tell your UI to load the content in that localized language. Examples:- en-US, fr-FR, etc
   */
  locale?: 'en-us';
  /**
   * The Support Url for the individuals to contact Microsoft for any issues or help.
   */
  supportUrl?: string;
  /**
   * Text displayed for the supportUrl
   */
  supportText?: string;
  /**
   *  This is a flag for enabling Proof Of Address (POA) validation.
   */
  enablePoa?: false;
  /**
   * This is Microsoft Correlation Vector Id value.
   */
  'ms-cv'?: string;
  nbf?: number;
  /**
   * Expiration date of the token.
   */
  exp?: number;
}

interface SelfStartUrlParams {
  token: string;
  applicationId: string;
  payload: TokenPayload | null;
}

function getSelfStartUrlParameters(): SelfStartUrlParams {
  const token = new URLSearchParams(window.location.search).get('token') || '';
  const payload = token ? getParsedJwt<TokenPayload>(token) : null;
  const applicationId = payload?.ApplicationId ?? '';

  return { applicationId, payload, token };
}

export default getSelfStartUrlParameters;
